import {listaLocais} from '@/domain/remocao/services'

const searchLocalizacao = function (uf = null, cidade = null) {
  console.log('click window search localizacao...')
  let window = 'windowSearch'
  !this.$uloc.window.get(window) && this.$uloc.window.new({
    wid: window,
    title: 'Buscar Localização',
    width: '800',
    height: '600',
    minHeight: '500',
    backdrop: true,
    clickOutside: false,
    windowClass: 'erp-window',
    contentClass: 'overflow-hidden bg-white',
    props: {
      columns: [
        {label: 'ID', value: 'id'},
        {label: 'Localização', value: 'nome'},
        {label: 'UF', value: (v) => { return v.uf ? v.uf.nome : v.ufOld}},
        {label: 'Cidade', value: (v) => { return v.cidade ? v.cidade.nome : v.cidadeOld}},
      ],
      fetchData: (terms, filters, done) => {
        console.log('Buscando dados: ', terms, filters, done)
        let rowsPerPage = 100
        listaLocais(rowsPerPage, filters.page, `&sortBy=nome&descending=desc&uf=${uf || ''}&cidade=${cidade || ''}&search=` + terms)
          .then(({data}) => {
            let filter = function (terms, {field, list}) {
              // const token = terms.toLowerCase()
              // return list.filter(item => ('' + item[field]).toLowerCase().includes(token))
              return list
            }

            let filteredData = filter(terms, {field: 'descricao', list: data.result})

            done(filteredData, {
              page: filters.page,
              rowsNumber: data.total, // response.totalRows,
              rowsPerPage: rowsPerPage
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, () => import('../../../layout/window/WindowSearch.vue'))
    .then((wid) => {
      this.$uloc.window.listen(wid, {
        selected: (wid, val) => {
          this.findedData = val
          this.$emit('localSelecionado', val)
          this.model = val.id
          this.__emit(val.id)
          this.description = val.id + ' - ' + val.nome
          setTimeout(() => {
            this.$refs.input && this.$refs.input.focus()
          }, 200)
        }
      })
      console.log(wid)
    }) // return wid
}

export default searchLocalizacao
