<template>
  <erp-s-field
      view="tl"
      :label="label || 'Localizacao'"
      :helper="helper"
      :no-label="noLabel"
      :helper-position="helperPosition || 'lb'"
      :error="error"
  >
    <helper-input-btn>
<!--      <erp-select
          @input="__set"
          :placeholder="placeholder || 'Selecione'"
          :options="asyncdata"
          v-model="model"
          :multiple="multiple"
          :loading="loading"
          :disable="disable"
          slot="input"
      ></erp-select>-->
      <erp-input
          ref="input"
          @input="__set"
          slot="input"
          simple-border
          v-model="description"
          @keydown.enter="searchLocalizacao"
          readonly
      >
      </erp-input>
      <a @click="openSearchLocalizacao"><i class="erp-icon search min"></i> </a>
      <u-btn v-if="!local.lat && btnDef" size="xs" flat label="Definir localização manual" icon="place"
             @click="$emit('showMap', local)" />
      <u-btn size="xs" icon="place" v-else-if="btnDef" label="Definida manual" color="primary">
        <u-popover hover :hover-delay="500" anchor="bottom middle" self="top middle" :offset="[10, 10]">
          <div class="wrapper-sm small">
            <div class="font-bold">{{local.endereco}}</div>
            <div class="m-t-xs">{{local.lat}}, {{local.lng}}</div>
            <div class="m-t"><e-btn label="Alterar" @click="$emit('showMap', local)" /></div>
          </div>
        </u-popover>
      </u-btn>
    </helper-input-btn>
  </erp-s-field>
</template>

<script>
import SelectMixin from '../../../../mixins/SelectMixin'
import InputMixin from '../../../../reuse/input/mixin'
import {locais} from '@/domain/remocao/services'
import {HelperInputBtn} from 'uloc-vue-plugin-erp'
import {UPopover} from 'uloc-vue'
import searchLocalizacao from '../../windows/search/SearchLocalizacao'

export default {
  name: 'DestinoSelect',
  mixins: [SelectMixin, InputMixin],
  components: {HelperInputBtn, UPopover},
  props: {
    cidade: {required: false},
    uf: {required: false},
    local: {required: true},
    ignoreForceModel: {type: Boolean, default: false},
    btnDef: {type: Boolean, default: true}
  },
  data () {
    return {
      loading: false,
      result: []
    }
  },
  created() {
    console.log('CREATED', this.value, this.model)
  },
  mounted() {
    console.log('MOUNTED', this.value, this.model)
  },
  watch: {
    /*cidade (v) {
      if(!this.ignoreForceModel) {
        this.model = null
        this.__emit(null)
      }
      console.log('Loading ', v)
      this.load(v)
    },*/
    model (v) {
      if (this.ignoreForceModel) return
      if (!v || !this.result || !this.result.length) return
      let test = this.result.find(item => item.id === v)
      if (test) {
        this.$emit('localSelecionado', test)
      }
    }
  },
  computed: {
    asyncdata () {
      let list = Array.isArray(this.result) ? this.result : []
      list = list.map((obj) => {
        return {
          value: obj.id,
          label: obj.nome
        }
      })
      !this.multiple && list.unshift({label: 'Selecione', value: null})
      return list
    }
  },
  methods: {
    searchLocalizacao: searchLocalizacao,
    openSearchLocalizacao () {
      console.log(this.uf, this.cidade)
      this.searchLocalizacao(this.uf ? this.uf.id || this.uf : null, this.cidade ? this.cidade.id : null)
    },
    load (cidade) {
      this.loading = true
      locais(cidade ? cidade.id : null)
        .then(response => {
          this.result = response.data.result
          this.loading = false
          this.$nextTick(() => {
            let find = this.result.find((item) => {
              if (this.model && this.model.id){
                return item.value && Number(item.value.id) === Number(this.model.id)
              }
              return item.value && Number(item.value.id) === Number(this.model)
            })
            if (find) {
              this.model = find.value.id
              this.__emit(this.model)
            }
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar a lista de destinos.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    }
  }
}
</script>
